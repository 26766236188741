import { motion } from "framer-motion"
import { graphql, Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import React from "react"
import Layout from "../components/Layout"
import Panels from "../components/Panels"
import SEO from "../components/seo"
import "./product.scss"
import OneColumnData from "../components/OneColumnData"
import TwoColumnData from "../components/TwoColumnData"
import { Helmet } from "react-helmet"

const Product = ({ data, location, pageContext, EN }) => {
  const image = getImage(
    (data &&
      data.FR &&
      data.FR.mainImage &&
      data.FR.mainImage.asset &&
      data.FR.mainImage.asset) ||
      (data.EN &&
        data.EN.mainImage &&
        data.EN.mainImage.asset &&
        data.EN.mainImage.asset)
  )

  const isMobile = typeof window !== "undefined" && window.innerWidth < 768

  return (
    <>
      <SEO
        title={
          pageContext.lang === "en"
            ? data && data.EN && data.EN.title && data.EN.title
            : data && data.FR && data.FR.titre && data.FR.titre
        }
        description={
          data &&
          data.sanityPage &&
          data.sanityPage.titre &&
          data.sanityPage.titre
        }
        image={image && image.images && image.images.fallback.src}
      />
      <Helmet>
        <script
          charset="utf-8"
          src="https://widget.bandsintown.com/main.min.js"
          defer
        ></script>
      </Helmet>

      <Panels />
      <Layout>
        <motion.div
          initial={{ visibility: "hidden" }}
          animate={{ visibility: "visible", transition: { delay: 1 } }}
          exit={{ visibility: "hidden", transition: { delay: 1 } }}
        >
          <Link to={pageContext.lang === "en" ? "/en" : "/"}>
            <button className="back-btn">❮</button>
          </Link>
          <h1 className="main-headline">
            {pageContext.lang === "en"
              ? data && data.EN && data.EN.title && data.EN.title.toLowerCase()
              : data && data.FR && data.FR.titre && data.FR.titre.toLowerCase()}
          </h1>

          {(data && data.FR && data.FR.no_img === false) ||
          (data && data.EN && data.EN.no_img === false) ? (
            <TwoColumnData data={data} />
          ) : (
            <OneColumnData data={data} />
          )}
        </motion.div>
      </Layout>
    </>
  )
}

export default Product

export const query = graphql`
  query($slug: String!) {
    EN: sanityPage(slug_en: { current: { eq: $slug } }) {
      title
      desc_en
      no_img
      mainImage {
        asset {
          url
          gatsbyImageData
        }
      }
      credits
    }

    FR: sanityPage(slug: { current: { eq: $slug } }) {
      titre
      desc
      no_img
      mainImage {
        asset {
          url
          gatsbyImageData
        }
      }
      credits
    }
  }
`
