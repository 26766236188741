import React from "react"
import { motion } from "framer-motion"
import { graphql, Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import Layout from "../components/Layout"
import Panels from "../components/Panels"
import SEO from "../components/seo"
import "../templates/product.scss"

const OneColumnData = ({ data }) => {
  const image = getImage(
    (data &&
      data.FR &&
      data.FR.mainImage &&
      data.FR.mainImage.asset &&
      data.FR.mainImage.asset) ||
      (data.EN &&
        data.EN.mainImage &&
        data.EN.mainImage.asset &&
        data.EN.mainImage.asset)
  )

  const isMobile = typeof window !== "undefined" && window.innerWidth < 768

  const styleCol = {
    display: "flex",
    justifyContent: "space-between",
    alignContent: "center",
    flexWrap: "wrap",
    gap: 60,
  }
  return (
    <motion.div
      initial={{ y: -60 }}
      animate={{ y: 0 }}
      transition={{ duration: 2.3 }}
    >
      {(data && data.FR && data.FR.desc) || (data.EN && data.EN.desc) ? (
        <div
          /*    style={styleCol} */
          dangerouslySetInnerHTML={{
            __html: data.FR && data.FR.desc && data.FR.desc,
          }}
        />
      ) : (
        <div
          /*     style={styleCol} */
          dangerouslySetInnerHTML={{
            __html: data.EN && data.EN.desc_en,
          }}
        />
      )}
    </motion.div>
  )
}

export default OneColumnData
