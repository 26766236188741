import React from "react"
import { motion } from "framer-motion"
import { graphql, Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import Layout from "../components/Layout"
import Panels from "../components/Panels"
import SEO from "../components/seo"
import "../templates/product.scss"

const TwoColumnData = ({ data }) => {
  const image = getImage(
    (data &&
      data.FR &&
      data.FR.mainImage &&
      data.FR.mainImage.asset &&
      data.FR.mainImage.asset) ||
      (data.EN &&
        data.EN.mainImage &&
        data.EN.mainImage.asset &&
        data.EN.mainImage.asset)
  )

  const isMobile = typeof window !== "undefined" && window.innerWidth < 768
  return (
    <div className="wrapper-single">
      <>
        <motion.div
          initial={{
            y: isMobile ? -100 : -300,
            x: isMobile ? null : 300,
            scale: isMobile ? 1 : 1.5,
            rotate: -2,
          }}
          animate={{ y: 0, x: 0, rotate: -2, scale: 1 }}
          transition={{ duration: 2.3 }}
        >
          <div className="inner-banner"></div>
          <div className="container fluid"></div>
          <GatsbyImage
            placeholderStyle={{ filter: `blur(1.5rem)` }}
            image={image}
            alt="image"
          />
          {(data &&
            data.FR &&
            data.FR.credits &&
            data.FR.credits &&
            `@ ${data.FR.credits}`) ||
            (data.EN &&
              data.EN.credits &&
              data.EN.credits &&
              `@ ${data.EN.credits}`)}
        </motion.div>
        <motion.div
          initial={{ y: -400 }}
          animate={{ y: 0 }}
          transition={{ duration: 2.3 }}
          className="single-second-column"
        >
          {(data && data.FR && data.FR.desc) || (data.EN && data.EN.desc) ? (
            <div
              dangerouslySetInnerHTML={{
                __html: data.FR && data.FR.desc && data.FR.desc,
              }}
            />
          ) : (
            <div
              dangerouslySetInnerHTML={{
                __html: data.EN && data.EN.desc_en,
              }}
            />
          )}
        </motion.div>
      </>
    </div>
  )
}

export default TwoColumnData
